<template>
  <v-app-bar
    app
    bottom
    color="secondary"
  >
    <v-container>
      <v-row align="center">
        <v-col cols="4">
          <Logo/>
        </v-col>
        <v-col
          cols="2"
        ></v-col>
        <v-col
          cols="3"
          align="right"
        >
          <v-btn
            icon
            color="tertiary"
            @click="previous"
            :disabled="firstStep || loading"
          >
            <v-icon x-large>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="3"
          align="right"
        >
          <v-btn
            color="tertiary"
            class="pa-0"
            :block="$vuetify.breakpoint.smAndUp"
            @click="next"
            :disabled="disabled || loading"
          >
            <div v-if="$vuetify.breakpoint.smAndUp">{{ text }}</div>
            <v-icon
              v-else
              x-large
              color="secondary"
            >
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import { redirectProfeminaOrigin, redirectProfeminaContactForm } from '@/utils/redirect';
import { equalityArray } from '@/utils/checkers';

export default {
  name: 'BottomBar',
  components: {
    Logo: lazyLoad('components/Logo'),
  },
  computed: {
    ...mapGetters('app', [
      'pwaMode',
      'localeCountry',
      'testView',
      'resultView',
      'loading',
    ]),
    ...mapGetters('testMacro', [
      'done',
    ]),
    ...mapGetters('testMicro', [
      'id',
      'activeStep',
      'selected',
      'choiceJourney',
      'firstStep',
      'lastStep',
    ]),
    disabled() {
      return this.testView ? !this.selected : false;
    },
    text() {
      return this.lastStep && this.testView
        ? this.$t('bottom_bar.advice') : this.$t('bottom_bar.next');
    },
  },
  methods: {
    ...mapActions('app', [
      'load',
    ]),
    ...mapActions('testMacro', [
      'completed',
      'completedChoiceJourney',
    ]),
    ...mapActions('testMicro', [
      'previousStep',
      'nextStep',
      'saveChoice',
      'saveTimestamp',
      'removeChoice',
      'removeTimestamp',
      'resetLength',
    ]),
    ...mapActions('user', [
      'postUserData',
    ]),
    ...mapActions('email', [
      'sendTestReportingEmail',
    ]),
    async next() {
      if (this.testView) {
        if (!this.lastStep) {
          this.saveChoice();
          this.saveTimestamp('choice');
          // SpecialCard -Problems - Path 7-6 - ContactForm
          if (equalityArray(this.choiceJourney, ['7', '6'])) {
            this.removeChoice(); // to avoid bug of moving next
            redirectProfeminaContactForm(this.$i18n.locale);
            return;
          }
          this.saveTimestamp('startQuestion');
          this.nextStep();
        } else {
          await this.load();
          await this.$router.push({ name: 'Result' });
          this.saveChoice();
          this.saveTimestamp('choice');
          this.sendTestReportingEmail();
          if (!this.pwaMode) {
            this.completed();
            this.completedChoiceJourney();
            await this.postUserData();
          }
        }
        return;
      }
      if (this.resultView) {
        this.completed();
        this.completedChoiceJourney();
        this.saveTimestamp('endAdvice');
        await this.postUserData();
        let route;
        if (this.pwaMode) {
          route = this.done ? 'Done' : 'Info';
        } else {
          route = 'Done';
        }
        this.$router.push({ name: route });
      }
    },
    previous() {
      if (this.firstStep) {
        if (this.pwaMode) {
          this.$router.go(-1);
         } else {
           redirectProfeminaOrigin(this.localeCountry);
         }
      }
      this.removeChoice();
      this.removeTimestamp('choice');
      this.removeTimestamp('startQuestion');
      this.resetLength();
      this.previousStep();
    },
  },
};
</script>

<style scoped>
/* span.btn__content {
  padding: 0 !important;
}

div.col {
  padding: 0 !important;
}

span.card__actions .btn {
  min-width: 0;
} */
</style>
